/* eslint-disable no-console */
import type { EditorReadyFn, TFunction } from '@wix/yoshi-flow-editor';
import {
  disablePopupsAutoOpen,
  showInstallationProgressBar,
  hideInstallationProgressBar,
  setOrdersPageState,
  getEditorOptions,
  navigateToPage,
  progressBarMoveToStepNumber,
  createFedopsLogger,
  createBILogger,
  syncPagesByOperations,
  getComponentByWidgetId,
  getParentWidgetById,
} from './editor.utils';
import {
  ecomComponentsConfiguration,
  handleFirstInstall,
  handleInstallationRetry,
  updatePage,
} from './installationUtils';
import { registerGfppEvents } from './gfppEvents';
import { SPECS } from 'root/appConsts/experiments';
import {
  CART_BUTTON_WIDGET_ID,
  OLO_CONTROLLER_WIDGET_ID,
  OPTIMUS_ORIGIN_TYPE,
  PAGE_DATA,
} from 'root/appConsts/consts';
import { OperationsClient } from 'root/api/operationClient';
import { registerComponentChangeEvents } from './componentEvents';
import { withRetry } from 'root/utils/retryUtils';
import { EcomComponent, type EcomComponentConfiguration } from '@wix/ecom-platform-sdk';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import { setWidgetProps } from 'root/utils/widgetPropsUtils';
import type { EditorSDK } from '@wix/platform-editor-sdk';

// resets `hidden` flag for cart button for new registered users
const enableCartButtonByEditorExperiment = async (
  editorSDK: EditorSDK,
  shouldDisplayCartButton: boolean
) => {
  if (shouldDisplayCartButton) {
    const componentRef = await getComponentByWidgetId(editorSDK, CART_BUTTON_WIDGET_ID);
    if (!componentRef) {
      return;
    }
    const widgetRef = await getParentWidgetById(editorSDK, componentRef, OLO_CONTROLLER_WIDGET_ID);
    widgetRef && setWidgetProps(editorSDK, widgetRef, { isCartButtonHidden: false });
  }
};

export const editorReadyImpl: EditorReadyFn = async (editorSDK, appDefId, options, flowAPI) => {
  const BI_SUBJECT = 'OLO Installation';
  const { experiments, translations, httpClient } = flowAPI;
  const t = translations.t as TFunction;
  const msid = await editorSDK?.info.getMetaSiteId('_token');
  const biReporterService = createBILogger({ flowAPI, options });

  try {
    const fedopsLogger = await createFedopsLogger({ editorSDK, flowAPI, biReporterService });
    const { isFirstInstall, isResponsive, isStudio } = getEditorOptions(options);
    let shouldOpenProgressBar = !isResponsive;
    ecomComponentsConfiguration.requireEcomComponents =
      async (): Promise<EcomComponentConfiguration> => {
        return {
          ecomComponents: {
            [EcomComponent.CHECKOUT]: {
              isInstalled: true,
            },
            [EcomComponent.THANK_YOU_PAGE]: {
              isInstalled: true,
            },
            [EcomComponent.CART]: {
              isInstalled: true,
            },
            [EcomComponent.CART_ICON]: {
              isInstalled: true,
            },
          },
          shouldInstallMembersArea: !experiments.enabled(SPECS.skipMemberAreaAutoInstall),
          managingAppId: APP_DEF_IDS.restaurants,
        };
      };

    try {
      isFirstInstall && (await showInstallationProgressBar(editorSDK, t, shouldOpenProgressBar));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log('closeProgressBar failed', e.message);
      shouldOpenProgressBar = false;
    }

    let operations;
    if (isFirstInstall) {
      await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
        fedopsLogger.restaurantsOrdersFirstInstallStarted();
        const { oloPageRef, menusPromise, lightboxesPromise } = await handleFirstInstall({
          editorSDK,
          t,
          appDefId,
          msid,
          isResponsive,
          isStudio,
          fedopsLogger,
          biReporterService,
          subjectType: BI_SUBJECT,
          shouldOpenProgressBar,
          hidePage: (options.origin.type as unknown) === OPTIMUS_ORIGIN_TYPE,
        });

        await progressBarMoveToStepNumber(editorSDK, t, 2, shouldOpenProgressBar);
        await Promise.all([lightboxesPromise]);
        await navigateToPage(editorSDK, PAGE_DATA.pageId);

        const isMultiPages = experiments.enabled(SPECS.multiPages);
        if (isMultiPages) {
          operations = await withRetry({
            fn: new OperationsClient(httpClient).getOperations,
            args: [],
            name: 'getOperations',
            maxTry: 5,
          });

          const [operation] = operations ?? [];
          if (operation) {
            await updatePage(editorSDK, oloPageRef, operation?.id);
            biReporterService?.reportOloEditorInstallationStepsEvent({
              step: 'installation_page_update',
              isFirstInstall,
              isStudio,
              msid,
              value: operation?.id,
            });
          } else {
            biReporterService?.reportOloEditorInstallationStepsEvent({
              step: 'installation_page_update_failed',
              isFirstInstall,
              isStudio,
              msid,
            });
          }
        }
        fedopsLogger.restaurantsOrdersFirstInstallEnded();
        return oloPageRef;
      });
      await progressBarMoveToStepNumber(editorSDK, t, 3, shouldOpenProgressBar);
    } else {
      biReporterService?.reportOloEditorInstallationStepsEvent({
        step: 'installation_retry',
        isStudio,
        msid,
      });

      await handleInstallationRetry({
        editorSDK,
        t,
        appDefId,
        msid,
        isResponsive,
        isStudio,
        fedopsLogger,
        biReporterService,
      });
    }

    const isMultiPages = experiments.enabled(SPECS.multiPages);
    if (isMultiPages) {
      await syncPagesByOperations({
        editorSDK,
        flowAPI,
        fedopsLogger,
        biReporterService,
        isStudio,
        msid,
        operationList: operations,
      });
    }

    setOrdersPageState(editorSDK);

    await disablePopupsAutoOpen(editorSDK);

    const isCartButtonVisibleOnEditor = experiments.enabled(SPECS.floatingCartButtonEditor);

    const isFloatingCartButtonEnabled =
      experiments.enabled(SPECS.floatingCartButton) || isCartButtonVisibleOnEditor;

    // eslint-disable-next-line no-console
    console.log('Online orders - panels logic start...');
    registerGfppEvents(editorSDK, fedopsLogger, t, options.origin.type, {
      isFloatingCartButtonEnabled,
    });

    // eslint-disable-next-line no-console
    console.log('Online orders - panels logic ended.');

    hideInstallationProgressBar(editorSDK, shouldOpenProgressBar);

    enableCartButtonByEditorExperiment(editorSDK, isCartButtonVisibleOnEditor);

    registerComponentChangeEvents({
      editorSDK,
      flowAPI,
      httpClient,
      fedopsLogger,
      pageIdPrefix: PAGE_DATA.pageId,
      reportError: flowAPI.reportError,
      sentry: flowAPI.errorMonitor,
      biLogger: biReporterService,
      msid,
      isStudio,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    biReporterService?.reportOloEditorInstallationStepsEvent({
      step: 'error',
      msid,
      errorMessage: e.message,
    });

    // eslint-disable-next-line no-console
    console.error('Online orders - installation error: ', e, e.message);
  }
};
